import React, { useEffect, useMemo } from 'react'
import countBy from 'lodash/countBy'
import flatten from 'lodash/flatten'
import { Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTable } from 'react-table'

import Layout from '../../components/layouts/Layout'
import MainBox from '../../components/common/MainBox'
import { Table, Header, HeaderCell, SmallTextCell, Row, isColumn, NarrowCell } from '../../components/common/Table'
import { TrainingIterationMetrics_training_iterations } from '../../hasura/graphQlQueries/types/TrainingIterationMetrics'
import { fetchTrainingIterationMetricsAction, medicalImagesSelector, MedicalImagesState } from '../../hasura/slices/medical-images'
import { usersSelector, UsersState } from '../../hasura/slices/users'
import { numberWithCommas, vetNameWithoutQualifications } from '../../lib/helpers'

const MAX_ROWS = 1000

const defaultColumn = {
  Cell: SmallTextCell,
}

export default function TaggingMetrics() {
  const dispatch = useDispatch()

  const { user, accessToken }: UsersState = useSelector(usersSelector)
  const { trainingIterationMetrics }: MedicalImagesState = useSelector(medicalImagesSelector)

  useEffect(() => {
    if (!user || !accessToken) return

    dispatch(fetchTrainingIterationMetricsAction(accessToken))
  }, [user, accessToken])

  const columns = useMemo(
    () => [
      {
        Header: 'ID',

        accessor: (t: TrainingIterationMetrics_training_iterations) => t,
        Cell: (c: { value: TrainingIterationMetrics_training_iterations }) => {
          return (
            <p
              onClick={() =>
                window.open(
                  `https://api.radimal.ai/console/data/default/schema/public/tables/training_iterations/browse?filter=id%3B%24eq%3B${c.value.id}`
                )
              }
              className="text-s m-0 underline pointer"
            >
              {c.value.id}
            </p>
          )
        },
      },
      {
        Header: 'Completed',
        accessor: 'completed_at',
        isDate: true,
      },
      {
        Header: 'Specialist',
        accessor: (t: TrainingIterationMetrics_training_iterations) => vetNameWithoutQualifications(t.vet?.display_name),
      },
      {
        Header: 'Species',
        accessor: 'species',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Positives',
        accessor: (t: TrainingIterationMetrics_training_iterations) =>
          t.predictions_normalizeds.filter((p) => p.grade && p.grade > 2).length,
      },
      {
        Header: 'Results',
        accessor: (t: TrainingIterationMetrics_training_iterations) => t,
        Cell: (c: { value: TrainingIterationMetrics_training_iterations }) => {
          const isScale = c.value.type === 'scale'
          const counts = isScale
            ? countBy(c.value.predictions_normalizeds.map((p) => p.grade || p.issue || p.display_name))
            : countBy(
                c.value.predictions_normalizeds.map((p) =>
                  p.key_values_json ? JSON.stringify(flatten(Object.values(p.key_values_json)).join('_').toLowerCase()) : p.issue
                )
              )
          return (
            <p className="text-s m-0">
              {Object.keys(counts)
                .filter((k) => isScale || counts[k] > 1)
                .map((k) => `${k}: ${counts[k]}`)
                .join(' / ')}
            </p>
          )
        },
      },
      {
        Header: 'Feedback',
        accessor: 'feedback',
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    // @ts-ignore
    columns,
    data: (trainingIterationMetrics || []).slice(0, MAX_ROWS),
    defaultColumn,
  })

  const colSpan = (data: any) => (isColumn(data, 'Description') || isColumn(data, 'Results') || isColumn(data, 'Feedback') ? 2 : 1)

  return (
    <Layout>
      <MainBox defaultPadding>
        <div className="d-flex align-items-end mb-2">
          <h4 className="bold mb-0">Tagging Metrics</h4>
          {trainingIterationMetrics && trainingIterationMetrics.length > MAX_ROWS && (
            <p className="ml-3 font-italic mb-0 text-s text--gray7">
              Displaying {numberWithCommas(MAX_ROWS)} of {numberWithCommas(trainingIterationMetrics.length)}
            </p>
          )}
        </div>

        {Array.isArray(trainingIterationMetrics) ? (
          <Table cellSpacing={0} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any, idx: number) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    return (
                      <HeaderCell key={idx} {...column.getHeaderProps()} colSpan={colSpan(column)}>
                        {column.Header !== 'Start' && <Header>{column.render('Header')}</Header>}
                      </HeaderCell>
                    )
                  })}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row: any, idx: number) => {
                prepareRow(row)

                return (
                  <Row key={idx} {...row.getRowProps()}>
                    {row.cells.map((cell: any, idx2: number) => {
                      return (
                        <NarrowCell colSpan={colSpan(cell)} key={idx2} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </NarrowCell>
                      )
                    })}
                  </Row>
                )
              })}
            </tbody>
          </Table>
        ) : (
          <Spinner color="primary" />
        )}
      </MainBox>
    </Layout>
  )
}
